<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 resources-screen gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="resource-header icon-hea1">
        <template v-slot:left>
          <router-link
            :to="{
              name: backLinkName,
              params: $route.params,
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{
            displayLabelName(
              "mega-containers",
              "select-container",
              "select-container"
            )
          }}
        </div>
      </top-header-menu-wrapper>
      <section class="search-wrapper time-zone-search">
        <div class="search-field">
          <input
            type="text"
            @keyup="searchQuery = $event.target.value"
            :placeholder="
              displayLabelName('mega-containers', 'select-container', 'search')
            "
          />
          <div class="icon search">
            <icon icon="#cx-app1-search-16x16" width="16" height="16" />
          </div>
        </div>
      </section>
      <div
        v-if="paginationData && paginationCountActive"
        class="clebex-pagination-count-wrapper"
      >
        <span class="clebex-pagination-count"
          >{{ topScreen }} - {{ bottomScreen }} /
          {{ paginationData.total }}</span
        >
      </div>
    </template>
    <div class="scroll"></div>
    <ul
      class="clebex-item-section pill"
      v-if="listOfContainers && listOfContainers.length"
    >
      <li
        class="clebex-item-section-item"
        v-for="container in listOfContainers"
        :key="container.id"
      >
        <button
          class="clebex-item-content-wrapper"
          @click="selectUserContainer(container)"
        >
          <span class="label">
            <span class="highlight">{{ container.name }}</span>
          </span>
          <span class="follow-up-icons">
            <span
              class="follow-up-icon-item"
              v-if="
                userContainers &&
                  userContainers.filter((item) => item === container.id).length
              "
            >
              <icon icon="#cx-app1-checkmark"></icon>
            </span>
          </span>
        </button>
      </li>
    </ul>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState } from "vuex";
import paginationMixin from "@/services/mixins/pagination/pagination-mixin";
export default {
  name: "MegaContainerSelectContainer",
  mixins: [paginationMixin],
  data() {
    return {
      processing: false,
      show: false,
      searchQuery: "",
    };
  },
  created() {
    this.getData();
    this.setAllUsers(false);
    this.setUsers([]);
  },
  computed: {
    ...mapState("megaContainers", ["containersList"]),
    ...mapState("companyNotifications", ["userContainers", "sendTo"]),
    listOfContainers() {
      const query = this.searchQuery;
      if (this.containersList && this.containersList.length) {
        if (query && query.length > 1) {
          return this.containersList.filter((item) =>
            item.name.toLowerCase().includes(query.toLowerCase())
          );
        } else {
          return this.containersList;
        }
      }
      return null;
    },
  },
  methods: {
    ...mapActions("megaContainers", ["getContainersList"]),
    ...mapActions("companyNotifications", [
      "setUserContainers",
      "setUsers",
      "setAllUsers",
      "setSendTo",
    ]),
    getData() {
      this.getContainersList({
        access_type_ids: [1],
      });
    },
    selectUserContainer(container) {
      if (this.userContainers && this.userContainers.includes(container.id)) {
        this.userContainers.splice(
          this.userContainers.indexOf(container.id),
          1
        );
      } else {
        this.userContainers.push(container.id);
      }

      this.setSendTo(null);
      if (this.userContainers.length) {
        this.setSendTo(
          this.displayLabelName("notifications.notifications.user-containers") +
            ": " +
            this.containersList
              .filter((c) => this.userContainers.includes(c.id))
              .map((c) => c.name)
              .join(", ")
        );
      }
    },
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
  },
  props: {
    backLinkName: {
      type: String,
      required: true,
    },
  },
};
</script>
